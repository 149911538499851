import { useRef } from 'react'
import { useMedia } from 'react-use'
import { Link, NavLink, useLocation } from 'react-router-dom'
import logo from '../../assets/img/logo.png'
import styled from 'styled-components'
import SvgIcon from '../svgIcon'
import { useEffect, useState } from 'react'
import { WOW } from 'wowjs'
import 'wowjs/css/libs/animate.css'

const Nav = styled.nav`
  @apply w-full absolute top-[90px] left-0 bg-white z-10 sm:relative sm:top-0 sm:w-auto sm:h-auto sm:bg-transparent;

  ul {
    @apply mb-8 sm:mb-0;
  }
  
  li {
    @apply px-[28px] py-[10px] sm:py-0;
  }

  a {
    @apply flex items-end text-[#112B44];

    &.active {
      @apply text-[#3D9838];
    }
  }

  .menu {
    @apply flex flex-col absolute top-12 left-4 py-4 px-2  w-[210px] bg-white rounded-lg;

    li {
      @apply relative p-3;

      &:before {
        @apply absolute top-0 left-0 w-full h-full opacity-0 rounded;
        content: '';
        background: var(--theme-primary);
      }

      &:hover {
        
        &:before {
          @apply opacity-30;
        }
      }
    }
  }
`

function Header() {
  const location = useLocation()
  const isMobile = useMedia('(max-width: 640px)')

  const [ show, setShow ] = useState(false)

  const handleToggle = (e) => {
    e.preventDefault()
    setShow(!show)
  }

  const menuRefs = useRef({})
  const [menuList, setMenuList] = useState([
    { title: 'Home', url: '/' },
    { title: 'Careers', url: '/careers' },
    { title: 'Contact', url: '/contact' }
  ])

  const handleMenu = (index, expand) => {
    setMenuList(menuList.map((menu, idx) => idx === index ? {...menu, expand: expand} : menu))
  }

  useEffect(() => {
    setShow(isMobile ? false : true)
  }, [isMobile])

  useEffect(() => {
    let wow = new WOW({
      live: false
    })
    wow.init()
    if(isMobile) setShow(false)

    return () => {
      wow = null
    }
  }, [location])

  useEffect(() => {
    function handleOutsideClick(evt) {
      Object.keys(menuRefs.current).forEach((key) => {
        if(menuRefs.current[key] && !menuRefs.current[key].contains(evt.target)) {
          handleMenu(key, true)
        }
      })
      
    }
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  return (
    <header className='relative w-full bg-white shadow-[0px_0px_20px_0px_rgba(113,196,140,0.14)]'>
      <div className='flex items-end wrapper pt-[26px] pb-[30px] justify-between text-white'>
        <Link to='/' className='shrink-0'>
          <img src={logo} className='h-[45px]' />
        </Link>
        {isMobile && (
          <a href='#' className='p-2 text-[var(--theme-gray)]' onClick={handleToggle}>
            <SvgIcon iconName='menu' className='w-6 h-6' />
          </a>
        )}
        <Nav className={`${!show ? 'hidden' : ''}`}>
          <ul className='sm:flex'>
            {menuList.map((menu, index) => (
              <li className='relative' key={index}>
                {menu.children
                  ? <a
                      href='#'
                      className={`${location.pathname.includes(menu.url) || !menu.expand ? 'active' : ''}`}
                      onClick={(evt) => {
                        evt.preventDefault()
                        evt.stopPropagation()
                        handleMenu(index, !menu.expand)
                      }}>
                        {menu.title}
                        <SvgIcon iconName='arrowDown' className="w-6 h-6" />
                      </a>
                  : <NavLink to={menu.url}>{menu.title}</NavLink>
                }
                {menu.children && (
                  <ul ref={ele => menuRefs.current[index] = ele} className={`${isMobile ? '' : !menu.expand ? 'menu shadow-[0px_0px_8px_3px_rgba(0,0,0,0.1)]' : 'hidden'}`}>
                  {menu.children.map((child, idx) => (
                    <li key={index + '_' + idx}>
                      <NavLink to={child.url}>{child.title}</NavLink>
                    </li>
                  ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </Nav>
      </div>
    </header>
  )
}

export default Header