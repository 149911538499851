import { Link } from "react-router-dom"
import styled from "styled-components"
import logo from "../../assets/img/logo_white.png"

const FooterWrap = styled.footer`
  background: #373F44;
  font-size: 14px;
  color: #fff;

  .wrapper {
    padding-top: 46px;
    padding-bottom: 55px;
    max-width: 1340px;
  }

  .copyright {
    margin-top: 38px;

    p {
      margin-top: 16px;
    }

    .desc {
      max-width: 320px;
    }
  }

  .menus {
    margin-top: 72px;

    dl {
      margin-left: 5%;
      width: 28%;
    }

    dt {
      margin-bottom: 16px;
      font-size: 20px;
    }

    a {
      color: inherit;
    }
  }
`

function Footer() {
  return (
    <FooterWrap>
      <div className="grid grid-cols-1 md:grid-cols-2 wrapper">
        <div className="copyright">
          <a href="/">
            <img src={logo} />
          </a>
          <p className="desc">Koala Capital Ltd is registered in the UK under company number 07886666.</p>
          <p>© 2024 · All rights reserved</p>
        </div>
        <div className="menus flex">
          <dl>
            <dt>
              <Link to='/careers'>Careers</Link>
            </dt>
            {/* <dd>
              <Link to='/privacy'>Privacy</Link>
            </dd>
            <dd>
              <Link to='/terms-and-conditions'>Terms and Conditions</Link>
            </dd> */}
          </dl>
          <dl>
            <dt>
              <Link to='/contact'>Contact</Link>
            </dt>
          </dl>
        </div>
      </div>
    </FooterWrap>
  )
}

export default Footer