import React from "react"
import styled from "styled-components"
import Header from "./header"
import Footer from "./footer"

const LayoutWrap = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
`

function Layout({ children }) {
  let defaultSlot = []
  let footerSlot = []
  let headerSlot = []
  React.Children.toArray(children).forEach((child) => {
    let { slotname } = child.props
    switch(slotname) {
      case 'header':
        headerSlot.push(child)
        break;
      case 'footer':
        footerSlot.push(child)
        break;
      default:
        defaultSlot.push(child)
        break;
    }
  })

  return (
    <LayoutWrap>
      {headerSlot.length
        ? headerSlot.map(item => ( item ))
        : <Header />}
      { defaultSlot.map(item => ( item )) }
      {footerSlot.length
        ? footerSlot.map(item => ( item ))
        : <Footer />}
    </LayoutWrap>
  )
}

export default Layout