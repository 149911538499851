import React, { lazy, Suspense, useEffect } from 'react'
import styled from 'styled-components'
import { Route, Routes, BrowserRouter, useLocation } from 'react-router-dom'
import SvgIcon from './components/svgIcon'
import Layout from './components/layout/index.js'
import Footer from './components/layout/footer'
const Index = lazy(() => import('./pages/index'))
const Careers = lazy(() => import('./pages/careers'))
const Contact = lazy(() => import('./pages/contact'))

const LoadingWrap = styled.div`
  @apply flex items-center justify-center w-screen h-screen;
`

function ScrollToTop({ children }) {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [pathname])

  return (
    children
  )
}

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={(
        <LoadingWrap>
          <SvgIcon iconName='loading' className='w-6 h-6' />
        </LoadingWrap>
      )}>
        <ScrollToTop>
          <Routes>
            <Route
              path='/'
              element={
                <Layout>
                  <Index />
                  <div className='footerWrap' slotname='footer'>
                    <Footer />
                  </div>
                </Layout>
              }
            />
            <Route
              path='/careers'
              element={
                <Layout>
                  <Careers />
                  <div className='footerWrap' slotname='footer'>
                    <Footer />
                  </div>
                </Layout>
              }
            />
            <Route
              path='/contact'
              element={
                <Layout>
                  <Contact />
                  <div className='footerWrap' slotname='footer'>
                    <Footer />
                  </div>
                </Layout>
              }
            />
          </Routes>
        </ScrollToTop>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
